import React from "react";
import "./home.scss";
import Header from "../../pages/Section/Header/header.jsx";
import Banner from "../../pages/Section/Banner/banner.jsx";
import About from "../../pages/Section/About/about.jsx";
import Promo from "../../pages/Section/Promo/promo.jsx";
import Fasilitas from "../../pages/Section/Fasilitas/fasilitas.jsx";
import Rumah from "../../pages/Section/Rumah/rumah.jsx";
import Lokasi from "../../pages/Section/Lokasi/lokasi.jsx";
import Footer from "../../pages/Section/Footer/footer.jsx";
import Tombol from "../../media/tombol.webp";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const floatingwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(More%20Info)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Banner />
      <About />
      <Promo />
      <Fasilitas />
      <Rumah />
      <Lokasi />
      <Footer />
      <img
        onClick={floatingwa}
        src={Tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={floatingwa} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
