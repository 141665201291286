import React from "react";
import "./footer.scss";
import logo from "../../../media/parkspring-logo.png";
import {Link} from "react-router-dom";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Park Spring Gading</h2>
            <h3>
              Jl. Tipar Cakung, Sukapura, Kec. Cilincing, Jakarta Utara, Daerah
              Khusus Ibukota Jakarta 14140
            </h3>
            <h4>Contact us : +6281283139739</h4>
            <Link to="/privacy-policy">
              <div className="footer-contact">Privacy Policy</div>
            </Link>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">
        © Park Spring Gading
        <div className="powered">Powered by Linktown</div>
      </div>
    </div>
  );
};

export default footer;
